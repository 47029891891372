import AgarManager from './components/agar/AgarManager.vue'
import AgarText from './components/agar_text/AgarText.vue'
import Printer from "./components/Printer.vue";
import ReceptionEntryPrinter from "./components/ReceptionEntryPrinter.vue";
import AntibioticManager from './components/antibiotics/AntibioticManager.vue'
import createRoomChannelSubscription from "../channels/room_channel.js";

import $ from 'jquery'
import {createApp} from "vue";

const initializeAgarManager = function () {
  let element = document.querySelector('#agar')
  if (element !== undefined && element !== null) {
    createApp(AgarManager, {
      agarList: JSON.parse(element.dataset.agarlist)
    }).mount(element)
  }
}

const initializeAgarText = function () {
  let element = document.querySelector('#cultural_aspect_exam')
  if (element !== undefined && element !== null) {
    createApp(AgarText, {
      agarList: JSON.parse(element.dataset.agarList),
      culturalAspectExam: JSON.parse(element.dataset.culturalAspectExam),
      trixSelector:'trix-editor[input=bulletin_document_results_cultural_aspect_exam]'
    }).mount(element)
  }
}

const initializeAntibiotics = function () {
  let element = document.querySelector('#antibiotics')
  if (element !== undefined && element !== null) {
    createApp(AntibioticManager, {
      antibioticData: Object.freeze(JSON.parse(element.dataset.antibioticdata))
    }).mount(element)
  }
}

const initializePrint = function () {
  document.querySelectorAll('.iframe-print').forEach(element => {
    createApp(Printer, {
      src: element.dataset.src
    }).mount(element)
  });
}

const initializeReceptionEntryPrinter = function () {
  let element = document.querySelector('#auto-printer')
  if (element !== undefined && element !== null) {
    createApp(ReceptionEntryPrinter, {
      src: element.dataset.src
    }).mount(element)
  }
}

const initializeSockets = function () {
  let element = document.querySelector('#connect-socket')
  if (element !== undefined && element !== null) {
    createRoomChannelSubscription()
  }
}

$(function () {
  initializeAgarManager();
  initializeAgarText();
  initializePrint();
  initializeReceptionEntryPrinter();
  initializeSockets();
  initializeAntibiotics();
});