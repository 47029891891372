import $ from 'jquery'

$(function () {
  const enableCompanyUserSwitch = function (e) {
    const $el = $(e)
    const companies = $el.data('companies')
    const companyId = $el.val()
    const userSwitchId = $el.data('company-user-switch')
    const $userSwitch = $(`#${userSwitchId}`)
    const currentUserId = $userSwitch.val()

    $userSwitch.empty().select2({
      theme: 'bootstrap4',
      data: companies[companyId]
    })

    if ($userSwitch.find('option[value=\'' + currentUserId + '\']').length) {
      $userSwitch.val(currentUserId).trigger('change')
    }
  }

  let $companyUserSwitch = $('[data-company-user-switch]')

  $companyUserSwitch.select2({
    theme: 'bootstrap4'
  })

  $companyUserSwitch.each((e, el) => {
    enableCompanyUserSwitch(el)
  })

  $companyUserSwitch.on('change', (e) => {
    enableCompanyUserSwitch(e.target)
  })
})