<template>
  <div>
    <Dialog :open="isOpen" @close="setIsOpen"
            class="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">

      <div class="tw-flex tw-items-center tw-justify-center tw-min-h-screen">
        <DialogOverlay class="tw-fixed tw-inset-0 tw-bg-black tw-opacity-30"/>

        <div class="tw-relative tw-max-w-lg tw-mx-auto tw-bg-white tw-rounded-lg tw-p-8">
          <h5 class="tw-text-lg tw-mb-5">Fisa de receptie a fost creata</h5>
          <h6 class="tw-text-base tw-mb-5">Apasati butonul de mai jos pentru printare</h6>

          <b-button @click.prevent.stop="handlePrint"
                    variant="primary"
                    :disabled="printing">
                <span v-if="printing">
                    <i class="las la-spinner la-spin"></i>
                    PRINT
                </span>
                <span v-else>
                    <i class="las la-print"></i>
                    PRINT
                </span>
          </b-button>
        </div>
      </div>
    </Dialog>

    <iframe ref="iframe" :src="iframeSrc" class="tw-w-0 tw-h-0 tw-border-0"></iframe>
  </div>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue'
import {onMounted, ref, watch} from "vue";
import {BButton} from "/vue/components/forms";

const props = defineProps({
  src: String
})

const iframeSrc = ref(null)
const iframe = ref(null)
const printing = ref(false)

const isOpen = ref(true)

function setIsOpen(value) {
  isOpen.value = value
}

watch(iframeSrc, (val) => {
  if (val) {
    printing.value = true
  }
})

const handlePrint = function () {
  if (iframeSrc.value) {
    iframe.value.contentWindow.focus()
    iframe.value.contentWindow.print()
  } else {
    iframeSrc.value = props.src
  }
}

const print = function () {
  iframe.value.contentWindow.focus()
  iframe.value.contentWindow.print()
  printing.value = false
}

onMounted(() => {
  iframe.value.onload = () => {
    if (iframeSrc.value) {
      print()
    }
  }
})

</script>
