import $ from 'jquery'
import _ from 'lodash'

window.addEventListener("trix-initialize", (e) => {
    const $trix = $(e.target)
    const $textKeywords = $trix.siblings('[data-trix-keywords-array]').first().data('trix-keywords-array')
    const $namedKeywords = $trix.siblings('[data-trix-keywords-named]').first().data('trix-keywords-named')

    const setupDocumentKeypresses = function () {
      $(document).keydown((e) => {
        if (e.target === $trix[0]) {
          const keyCode = e.key

          switch (keyCode) {
            case 'Tab':
            case 'Enter':
              if (optionsAreVisible()) {
                e.preventDefault()
                $trix.trigger('trix-select')
              }
              break;
            case 'ArrowDown':
              if (optionsAreVisible()) {
                e.preventDefault()
                highlightNextOption()
              }
              break;
            case 'ArrowUp':
              if (optionsAreVisible()) {
                e.preventDefault()
                highlightPrevOption()
              }
              break;
            case 'Escape':
              if (optionsAreVisible()) {
                e.preventDefault()
                hideOptions()
              }
              break;
          }
        }
      })
    }


    const hideOptions = function () {
      removeAllHighlights()
      $optionsDiv.hide()
    }

    const optionsAreVisible = function () {
      return $optionsDiv.is(':visible') && !$optionsDiv.is(':empty')
    }

    const makeOptionsContainer = function () {
      return "<div class='trix-autocomplete-option-list'></div>"
    }

    const makeOption = function (option, idx) {
      return `<div data-no='${idx}' class='row trix-autocomplete-option' data-trix-text='${option.text}'><div class='col-md-12'>${option.label}</div></div>`
    }

    const showOptionsAtCursor = function () {
      const rect = $editor.getClientRectAtPosition($editor.getPosition())
      if (rect) {
        $optionsDiv.css('left', rect.left + 10)
        $optionsDiv.css('top', rect.top - 4)
        highlightFirstOption()
        $optionsDiv.show()
      }
    }

    const getLastWord = function () {
      const char = $editor.getDocument().toString().charAt($editor.getPosition() - 1)
      if (char === ' ' || char === '\n') {
        return ''
      }

      const currentText = $editor.getDocument().toString()
      const words = currentText.substring(0, $editor.getPosition()).trim().split(/\s+/)
      return words[words.length - 1]
    }

    const filterOptions = function (text) {
      if (text.length === 0) {
        return []
      }

      const textStartingWith = _.filter($textKeywords, (opt) => {
        return opt.text.toLowerCase().startsWith(text.toLowerCase())
      }).map((opt) => {
        return {
          text: opt.text,
          label: opt.text
        }
      })

      let namedStartingWith = _.filter($namedKeywords, (opt) => {
        return opt.name.toLowerCase().startsWith(text.toLowerCase()) && opt.text.length > 0
      })

      namedStartingWith = _.map(namedStartingWith, (opt) => {
        return {
          text: opt.text,
          label: `${opt.text} (${opt.name})`
        }
      })

      return textStartingWith.concat(namedStartingWith)
    }

    const addOptions = function (options) {
      $optionsDiv.html('')
      options.forEach((option, idx) => {
        const $optionTemplate = $(makeOption(option, idx))
        $optionsDiv.append($optionTemplate)
        $optionTemplate.on('click', () => {
          highlightOption($optionTemplate)
        })
      })
    }

    const highlightOption = function (option) {
      removeAllHighlights()
      option.addClass('highlighted')
      $trix.trigger('trix-select')
    }

    const removeAllHighlights = function () {
      $optionsDiv.children().removeClass('highlighted')
    }

    const highlightNextOption = function () {
      const current = currentHighlightedOption()
      if (current) {
        const next = current.next()
        if (next[0]) {
          removeAllHighlights()
          next.addClass('highlighted')
        }
      }
    }

    const highlightFirstOption = function () {
      removeAllHighlights()
      $optionsDiv.children().first().addClass('highlighted')
    }

    const highlightPrevOption = function () {
      const current = currentHighlightedOption()
      if (current) {
        const prev = current.prev()
        if (prev[0]) {
          removeAllHighlights()
          prev.addClass('highlighted')
        }
      }
    }

    const currentHighlightedOption = function () {
      return $optionsDiv.find('.highlighted')
    }

    const $optionsDiv = $(makeOptionsContainer())
    const $editor = $trix[0].editor

    $trix.after($optionsDiv)
    $optionsDiv.hide()

    $trix.on('trix-change', (e) => {
      $trix.data('last-position', $editor.getPosition())
      addOptions(filterOptions(getLastWord()))
      if (getLastWord().length > 0) {
        showOptionsAtCursor()
        highlightFirstOption()
      } else {
        hideOptions()
      }
      $($editor.element.inputElement).trigger('change')
    })

    $trix.on('trix-select', (e) => {
      const option = currentHighlightedOption()
      if (option && option[0]) {
        const text = option.data('trix-text')
        const lastWord = getLastWord()
        $editor.recordUndoEntry('Content updated')
        $editor.setSelectedRange([$trix.data('last-position') - lastWord.length, $trix.data('last-position')])
        $editor.deleteInDirection('forward')
        $editor.insertHTML(text + ' ')
      }
      hideOptions()
    })

    $trix.on('click', hideOptions)
    setupDocumentKeypresses()
  }
);