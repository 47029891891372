<template>
  <div class="btn-group-toggle" :class="{ 'btn-group': !stacked, 'btn-group-vertical': stacked }">
    <template v-for="option in options">
      <slot>
        <b-form-radio
            :model-value="modelValue"
            :name="name"
            :option="option"
            :button-variant="buttonVariant"
            :size="size"
            :is-equal="isEqual"
            @update:modelValue="updateValue"
        />
      </slot>
    </template>
  </div>
</template>

<script setup lang="ts">

import {PropType} from "vue";
import BFormRadio from "./BFormRadio.vue";

export interface BFormRadioOption {
  text: string
  value: any
}

const props = defineProps({
  name: {
    type: String
  },
  modelValue: [String, Number, Object],
  options: {
    type: Object as PropType<BFormRadioOption[]>,
    required: true
  },
  buttonVariant: {
    type: String
  },
  stacked: {
    type: Boolean,
    default: false
  },
  size: {
    type: String
  },
  isEqual: Function
})

const emit = defineEmits(['update:modelValue', 'change'])

const updateValue = (value) => {
  emit('update:modelValue', value)
  emit('change', value)
}
</script>