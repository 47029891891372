<template>
  <div class="d-inline-block ml-2">
    <b-button @click.prevent.stop="handlePrint"
              variant="primary"
              :disabled="printing"
              size="sm">
            <span v-if="printing">
                <i class="las la-spinner la-spin"></i>
                PRINT
            </span>
            <span v-else>
                <i class="las la-print"></i>
                PRINT
            </span>
    </b-button>
    <iframe ref="iframe" :src="iframeSrc" class="iframe-hidden border-0"></iframe>
  </div>
</template>

<script setup lang="ts">

import {onMounted, ref, watch} from "vue"
import {BButton} from "/vue/components/forms";

const props = defineProps({
  src: String
})

const iframe = ref(null)
const iframeSrc = ref(null)
const printing = ref(false)

watch(iframeSrc, (val) => {
  if (val) {
    printing.value = true
  }
})

const handlePrint = function () {
  if (iframeSrc.value) {
    iframe.value.contentWindow.focus()
    iframe.value.contentWindow.print()
  } else {
    iframeSrc.value = props.src
  }
}

const print = function () {
  iframe.value.contentWindow.focus()
  iframe.value.contentWindow.print()
  printing.value = false
}

onMounted(() => {
  iframe.value.onload = () => {
    if (iframeSrc.value) {
      print()
    }
  }
})

</script>

<style scoped>
.iframe-hidden {
  width: 0;
  height: 0;
}
</style>