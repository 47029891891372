<template>
  <div class="d-inline-block align-top">
    <div v-if="title">{{ title }}</div>
    <b-form-radio-group
        v-model="selectedOption"
        :stacked="stacked"
        button-variant="light"
        :options="getStepOptions"
        size="sm"/>
  </div>
</template>

<script setup lang="ts">
import {BFormRadioGroup} from "/vue/components/forms";
import {computed} from "vue";

const props = defineProps({
  step: {
    type: String
  },
  stepData: {
    type: Array
  },
  title: {
    type: String
  },
  stacked: {
    type: Boolean,
    default: true
  },
  buttonVariant: {
    type: String
  },
  modelValue: String
})
const emit = defineEmits(['update:modelValue'])

const selectedOption = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const getStepOptions = computed(() => props.stepData.map(t => ({text: t, value: t})))

</script>