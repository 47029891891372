<template>
  <div class="mb-5">
    <div class="card mb-3" v-if="settings">
      <div class="card-header">Setari</div>

      <div class="card-body">
        <div class="row">
          <div class="col-md-2 mb-3" v-for="percentArray in percentages">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">S{{percentArray[1]}}</div>
              </div>
              <b-form-input v-model.number="percentArray[0]" type="number"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row sticky-top pb-3 pt-3 bg-white">
      <div class="col">
        <b-form-input v-model="query" placeholder="Cautare" ref="queryInput"/>
      </div>

      <div class="col text-right tw-flex tw-gap-3 tw-justify-end">
        <b-form-radio-group
            v-model="searchAlgorithm"
            :options="[{text: 'Cod', value: 'code'}, {text: 'Nume', value: 'fuzzy'}]"
            button-variant="light"
        />
        <b-form-checkbox buttons button-variant="light" v-model="settings">
          <i class="las la-cogs"></i>
        </b-form-checkbox>

        <b-form-checkbox buttons
                         :button-variant="showOnlyTulpinMatchErrors ? 'danger' : 'light'"
                         v-model="showOnlyTulpinMatchErrors"
                         :title="showOnlyTulpinMatchErrors ? 'Click: Arata toate antibioticele' : 'Click: Arata antibioticele cu erori'">
          <i class="las la-exclamation-triangle"></i>
        </b-form-checkbox>

        <b-form-select v-model="preferredBacteria" :options="preferredBacteriaOptions" class="w-auto"/>
      </div>
    </div>

    <antibiotic
        v-show="queryMatchesAntibiotic(antibiotic)"
        v-for="antibiotic in antibioticData.data"
        :key="antibiotic.name"
        :valObject="antibioticData.object"
        :dataObject="antibiotic"
        @select="handleSelection"
        :percentages="percentages"
        :preferredBacteria="preferredBacteria"
        :passingQueryNumber="passQueryNumberIfNeeded(antibiotic)"
        :showOnlyTulpinMatchErrors="showOnlyTulpinMatchErrors"
        :searchAlgorithm="searchAlgorithm"
    />


  </div>
</template>

<script>
import Antibiotic from './Antibiotic.vue'
import {orderBy, uniq} from "lodash";
import {BFormInput, BFormRadioGroup, BFormCheckbox, BFormSelect} from '../forms'

export default {
  props: ['antibioticData'],

  data: function () {
    return {
      searchAlgorithm: 'fuzzy', // can be code or fuzzy
      showOnlyTulpinMatchErrors: false,
      preferredBacteria: null,
      settings: false,
      query: '',
      percentages: [
        [20, '+'],
        [50, '++'],
        [100, '+++']
      ]
    }
  },

  computed: {
    preferredBacteriaOptions: function () {
      return this.defaultRadioOptions(this.getTulpins())
    },

    queryMatchingAntibiotics: function () {
      if (this.query) {
        return this.antibioticData.data.filter(antibiotic => {
          if (this.searchAlgorithm === 'code') {
            return this.matchAntibioticCodes(this.query, antibiotic.codes)
          } else {
            return this.matchAntibioticName(this.query, antibiotic.translated_name)
          }
        })
      } else {
        return this.antibioticData.data
      }
    },

    queryNumber: function () {
      if (this.query && this.queryMatchingAntibiotics.length === 1) {
        let regex = /\d+/g
        let strNumber = this.query.match(regex)
        if (strNumber) {
          let number = parseInt(strNumber[0], 10)
          if (number) {
            return number
          }
        }
      }
      return null
    }
  },

  mounted: function () {
    let text = this.antibioticData.object.result_exam || ''
    let tulpins = this.getTulpins().map(tulpinName => {
      return {
        name: tulpinName,
        index: text.indexOf(tulpinName)
      }
    }).filter(obj => {
      return obj.index !== -1
    })

    let sortedTulpins = orderBy(tulpins, ['index'], ['asc'])

    let tulpin = sortedTulpins[0]

    if (tulpin) {
      this.preferredBacteria = tulpin.name
    } else {
      this.preferredBacteria = ''
    }
  },

  methods: {

    passQueryNumberIfNeeded: function (antibiotic) {
      if (this.queryNumber && this.queryMatchingAntibiotics[0] === antibiotic) {
        return this.queryNumber
      }
      return null
    },

    queryMatchesAntibiotic: function (antibiotic) {
      return this.queryMatchingAntibiotics.includes(antibiotic)
    },

    defaultRadioOptions: function (ary) {
      return ary.map(t => ({text: t, value: t}))
    },

    getTulpins: function () {
      return uniq(this.antibioticData.data.map(antibiotic => {
        return antibiotic.properties.tulpins.map(tulpin => {
          return tulpin.name
        })
      }).flat())
    },

    handleSelection: function (antibioticName) {
      this.$nextTick(() => {
        this.$refs.queryInput.$refs.input.focus()
        this.$refs.queryInput.$refs.input.select()
        // this.$refs.queryInput.focus()
        // this.$refs.queryInput.select()
      })
    },

    matchAntibioticCodes: function (pQuery, codes) {
      let query = pQuery.replace(/[0-9]/g, '').toLowerCase()
      return codes.filter(el => {
        return el.toLowerCase() === query
      }).length > 0
    },

    matchAntibioticName: function (pQuery, val) {
      let query = pQuery.replace(/[0-9]/g, '').toLowerCase()
      val = val.toLowerCase()


      let p = Array.from(query).reduce((function (a, v, i) {
        return ''.concat(a, '[^').concat(query.substr(i), ']*?').concat(v);
      }), '');
      let re = RegExp(p);
      if (query[0] && val[0]) {
        return val.match(re) && query[0] === val[0];
      } else {
        return val.match(re);
      }
    }
  },

  components: {BFormSelect, BFormCheckbox, BFormRadioGroup, BFormInput, Antibiotic}
}
</script>
