import consumer from "./consumer.js"

function createRoomChannelSubscription() {
  consumer.subscriptions.create("RoomChannel", {
    connected() {
      console.log("Connected to room channel");
    },

    disconnected() {

    },

    received(data) {
      if (data.redirect_to) {
        if (!document.hidden) {
          window.location = data.redirect_to
        }
      }
    }
  })
}

export default createRoomChannelSubscription