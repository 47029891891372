<template>
  <input
      :id="id"
      type="text"
      :name="name"
      class="form-control"
      :value="modelValue"
      ref="input"
      @input="updateValue">
</template>

<script setup lang="ts">
import {defineProps, ref} from "vue";
import {dynamicId} from "/vue/composables/DynamicId";

defineProps({
  name: {
    type: String
  },
  modelValue: {
    type: [String, Number]
  }
})

const id = ref(dynamicId())

const emit = defineEmits(['update:modelValue'])

const input = ref(null)

const updateValue = (event) => {
  emit('update:modelValue', event.target.value)
}

const focus = function() {
  input.value.focus()
}

</script>