export default {
  locale: {
    ro: {
      "format": "DD-MM-YYYY",
      "separator": " - ",
      "applyLabel": "Aplica",
      "cancelLabel": "Anuleaza",
      "fromLabel": "De la",
      "toLabel": "Pana La",
      "customRangeLabel": "Personalizat",
      "daysOfWeek": [
        "Du",
        "Lu",
        "Ma",
        "Mi",
        "Jo",
        "Vi",
        "Sa"
      ],
      "monthNames": [
        "Ianuarie",
        "Februarie",
        "Martie",
        "Aprilie",
        "Mai",
        "Iunie",
        "Iulie",
        "August",
        "Septembrie",
        "Octombrie",
        "Noiembrie",
        "Decembrie"
      ],
      "firstDay": 1
    }
  }
}
