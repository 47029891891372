import UJS from '@rails/ujs'
if (!window._rails_loaded) {
  UJS.start()
}

import $ from 'jquery'

import 'bootstrap'
import 'lodash'
import 'cocoon-js-vanilla'
import select2 from 'select2';

import 'trix'
import dayjs from "dayjs";
import 'daterangepicker-dayjs'

import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isoWeek from "dayjs/plugin/isoWeek";
import arraySupport from "dayjs/plugin/arraySupport";
import badMutable from "dayjs/plugin/badMutable";
import customParseFormat from 'dayjs/plugin/customParseFormat'

// Load plugins
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(isoWeek);
dayjs.extend(arraySupport);
dayjs.extend(badMutable);
dayjs.extend(customParseFormat);

import '../vue/initializers'
import '../admin/SaveFormHelper'
import '../admin/CompanySearch'
import '../admin/CompanyUserSwitch'
import '../admin/TextCompleters' //
import '../admin/TrixAutocomplete'
import '../admin/DateRangePicker'

select2($);

$(function() {

  $('#company-search').focus();
  $('#search_query').focus();

  $('[data-toggle="tooltip"]').tooltip();

  $(".selectize").select2({
    theme: 'bootstrap4'
  });

  // needs to be the parent of link_to_add_association
  $('.links').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
    $(".selectize").select2({
      theme: 'bootstrap4'
    });
  });

  // auto-populates a text field with a date based on a button value. find it in bulletins/edit
  $('[data-bulletin-alert-date]').on('click', (e) => {
    let $el = $(e.target);
    $($el.data('bulletin-alert-target')).val($el.data('bulletin-alert-date'))
  });

  $('[data-medium-fill-target]').on('click', (e) => {
    let $el = $(e.target);
    let $target = $('#' + $el.data('medium-fill-target'));
    $target.val($el.text());
    $target.trigger('change');
  });

})

