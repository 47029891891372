<template>
  <select v-model="selected" :name="name" class="custom-select">
    <option v-for="option in options" :value="option.value">{{option.text}}</option>
  </select>
</template>

<script setup lang="ts">

import {computed, PropType, ref} from "vue";
import {dynamicId} from "/vue/composables/DynamicId";

export interface BFormSelectOption {
  text: string
  value: any
}

const props = defineProps({
  name: {
    type: String
  },
  options: {
    type: Object as PropType<BFormSelectOption[]>,
    required: true
  },
  modelValue: String
})

const emit = defineEmits(['update:modelValue'])

const id = ref(dynamicId())

const selected = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})


</script>