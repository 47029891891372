<template>
    <div>
        <div v-if="builderEnabled">
            <agar-text-data
                    v-for="agar in checkedAgars"
                    :agar="agar"
                    :culturalAspectExam="culturalAspectExam"
                    @commitText="commitText"/>
        </div>
        <div v-else>
            <b-button variant="success" @click="builderEnabled = true">DESCHIDE EXAMEN ASPECT CULTURAL</b-button>
        </div>
    </div>
</template>

<script setup lang="ts">
  import AgarTextData from './AgarTextData.vue'
  import {computed, ref} from "vue";
  import {BButton} from "/vue/components/forms";
  import jquery from 'jquery'

  const props = defineProps({
    agarList: Array,
    culturalAspectExam: Object,
    trixSelector: String
  })

  const builderEnabled = ref(false)

  const commitText = function(text) {
    let el = jquery(props.trixSelector)

    // @ts-ignore
    el[0].editor.insertHTML(text)
    // @ts-ignore
    el[0].editor.insertLineBreak()
    // @ts-ignore
    el[0].editor.decreaseNestingLevel()
  }
  // @ts-ignore
  const checkedAgars = computed(() => props.agarList.filter(obj => obj.checked === '1'))

</script>
