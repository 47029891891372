<template>
  <div class="mb-4" v-show="!showOnlyTulpinMatchErrors || (showOnlyTulpinMatchErrors && currentTulpinMatchError)">
    <input type="hidden" v-model="antibioticResult" :name="fieldName()">

    <div class="card" :class="{ 'border-danger': currentTulpinMatchError }">
      <div class="card-header">
        <div class="row">
          <div class="col-md-4">
            <h4>
              <i class="text-danger las la-exclamation-triangle"
                 v-if="currentTulpinMatchError && currentTulpin"
                 :title="`Pentru ${antibioticValue} la ${currentTulpin.name} rezistenta ar fi trebuit sa fie ${processSensitivity(antibioticValue)}${processSuffix(antibioticValue)}`">
              </i>
              {{ dataObject.translated_name }}
              <span v-if="searchAlgorithm === 'code'">({{ dataObject.codes.join(',') }})</span>
              <span class="badge badge-primary" v-if="antibioticResult">{{ antibioticResult }}</span>
            </h4>
          </div>

          <div class="col-md-8 text-right">
            <b-form-radio-group
                v-model="currentTulpin"
                button-variant="light"
                :options="tulpinOptions"
                :is-equal="equalTulpin"
                size="sm"
                @change="changedCurrentTulpin"/>
          </div>

        </div>
      </div>

      <div class="card-body">
        <div class="row mb-2">
          <div class="col">
            <div class="btn-group-toggle btn-group mr-2 first-input-bold sensitivity-scaled" v-for="(list, index) in groupedAntibioticValueLists">

              <b-form-radio
                  v-for="option in defaultRadioOptions(list)"
                  :name="fieldName('value')"
                  :id="`${dataObject.name}_${option.value}`"
                  :button-variant="groupedAntibioticColors[index]"
                  v-model="antibioticValue"
                  :option="option"
                  size="sm"
                  :class="sensitivityClass(option.value)"
                  :title="`${processSensitivity(option.value)}${processSuffix(option.value)}`" option="">
                {{ option.text }}
              </b-form-radio>

            </div>

          </div>
        </div>

        <div class="row" v-show="antibioticValue && antibioticValue !== '-'">
          <div class="col">
            <b-form-radio-group
                v-model="resultBase"
                :options="defaultRadioOptions(antibioticResultList)"
                button-variant="light"
                class="mr-3"/>

            <b-form-radio-group
                v-model="resultPrefix"
                v-if="prefixOptions"
                :options="defaultRadioOptions(prefixOptions)"
                button-variant="warning"
                class="mr-3"/>

            <b-form-radio-group
                v-model="resultSuffix"
                v-if="suffixOptions"
                :options="defaultRadioOptions(suffixOptions)"
                buttons
                button-variant="success"/>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {isEqual, uniq} from "lodash";
import BFormRadioGroup from "../forms/BFormRadioGroup.vue";
import BFormRadio from "../forms/BFormRadio.vue";

export default {
  components: {BFormRadio, BFormRadioGroup},
  data: function () {
    return {
      currentTulpin: null,
      antibioticValue: this.originalValue('value'),
      resultPrefix: this.getResultPrefix(this.originalValue()),
      resultSuffix: this.getResultSuffix(this.originalValue()),
      resultBase: this.getResultBase(this.originalValue()),
    }
  },

  props: ['valObject', 'dataObject', 'percentages', 'preferredBacteria', 'showOnlyTulpinMatchErrors', 'passingQueryNumber', 'searchAlgorithm'],

  computed: {
    currentTulpinMatchError: function () {
      if (this.resultBase === '-') return false

      if (this.antibioticValue) {
        // The base (R/M-S/S) and suffix (none/+/++/+++) must match
        return (this.resultBase !== this.processSensitivity(this.antibioticValue) || this.resultSuffix !== this.processSuffix(this.antibioticValue))
      }
      return false
    },

    tulpins: function () {
      var aggregatedTulpins = []

      this.dataObject.properties.tulpins.forEach(tulpin => {
        let existingSensitivity = aggregatedTulpins.find(aggregatedTulpin => {
          return isEqual(aggregatedTulpin.sensitivity, tulpin.sensitivity)
        })

        if (existingSensitivity) {
          existingSensitivity.name += `,${tulpin.name}`
        } else {
          aggregatedTulpins.push({
            name: tulpin.name,
            sensitivity: [...tulpin.sensitivity]
          })
        }
      })

      aggregatedTulpins.forEach(tulpin => {
        tulpin.name = uniq(tulpin.name.split(',')).join(', ')
      })

      return aggregatedTulpins
    },

    tulpinOptions: function () {
      return this.tulpins.map(t => ({text: t.name, value: t}))
    },

    antibioticResultList: function () {
      return ['-', 'R', 'M-S', 'S']
    },

    groupedAntibioticValueLists: function () {
      return [
        ["-"],
        ["6", "7", "8", "9"],
        ["10", "11", "12", "13", "14", "15", "16", "17", "18", "19"],
        ["20", "21", "22", "23", "24", "25", "26", "27", "28", "29"],
        ["30", ">30"]
      ]
    },

    groupedAntibioticColors: function () {
      return [
        'light',
        'light',
        'light-200',
        'light-300',
        'light-400'
      ]
    },

    antibioticResult: function () {
      return `${this.resultPrefix}${this.resultBase}${this.resultSuffix}`
    },

    suffixOptions: function () {
      return this.resultBase === 'S' ? ['+', '++', '+++'] : null;
    },

    prefixOptions: function () {
      return this.resultBase === 'S' || this.resultBase === 'M-S' ? ['!', '!!'] : null;
    },

    sensitivity: function () {
      return this.processSensitivity(this.antibioticValue)
    }
  },

  watch: {
    passingQueryNumber: function (number) {
      if (number >= 30) {
        this.antibioticValue = ">30"
      } else if (number >= 6) {
        this.antibioticValue = String(number)
      }
    },
    preferredBacteria: function (bacteria) {
      let tulpins = this.tulpins
      if (bacteria === '') {
        // No preferred bacteria detected, select the first tulpin on each antibiotic
        this.currentTulpin = tulpins[0]
        return;
      }
      let tulpin = tulpins.find(tulpin => {
        return tulpin.name.includes(bacteria)
      })
      if (tulpin && tulpin !== this.currentTulpin) {
        this.currentTulpin = tulpin
      } else {
        this.currentTulpin = tulpins[0]
      }
    },

    suffixOptions: function (options) {
      // This assumes it will always be S
      if (!options) {
        this.resultSuffix = ""
      } else {
        this.updateSuffix()
      }
    },

    prefixOptions: function (options) {
      if (!options) {
        this.resultPrefix = ""
      }
    },

    resultBase: function (res) {
      this.resultPrefix = ""
    },

    antibioticValue: function (val) {
      let resultBase = this.sensitivity
      if (resultBase) {
        this.resultBase = resultBase
      }
      this.updateSuffix()
      this.$emit('select', this.dataObject.name)
    },

    antibioticResult: function (val) {
      this.$emit('select', this.dataObject.name)
    },
  },

  methods: {
    equalTulpin(t1, t2) {
      if (!t1 || !t2) { return false }
      return t1.name === t2.name
    },

    changedCurrentTulpin: function (value) {
      // This is happening via the @change event, only on user action
      this.currentTulpin = value
      // let resultBase = this.sensitivity
      // if (resultBase) {
      //   this.resultBase = resultBase
      // }
      // this.updateSuffix()
    },

    processSensitivity: function (antibioticValue) {
      if (!this.currentTulpin) {
        return null
      }

      let val = parseInt(antibioticValue, 10)
      if (!isNaN(val)) {
        let sensitivity = this.currentTulpin.sensitivity
        let resistantTopLimit = sensitivity[0]
        let mediumTopLimit = sensitivity[1]
        let sensitiveLowerLimit = sensitivity[2]

        if (resistantTopLimit && val <= resistantTopLimit) {
          return 'R'
        } else if (mediumTopLimit && val <= mediumTopLimit) {
          return 'M-S'
        } else if (sensitiveLowerLimit && val >= sensitiveLowerLimit) {
          return 'S'
        } else {
          return 'R'
        }
      } else if (antibioticValue === '-') {
        return '-'
      } else if (antibioticValue === '>30') {
        return 'S'
      }
      return null
    },

    sensitivityClass: function (value) {
      let sensitivity = this.processSensitivity(value)
      switch (sensitivity) {
        case 'R': {
          return 'resistant'
        }
        case 'M-S': {
          return 'medium'
        }
        case 'S': {
          return 'sensitive'
        }
      }
      return ''
    },

    processSuffix: function (value) {
      if (!this.currentTulpin) {
        return ''
      }

      if (this.processSensitivity(value) !== 'S') {
        return ''
      }

      // This only happens for S values from sensitivity min to >30 (ie: 31)
      let percentages = this.percentages
      // Assuming this is a value since it preselected S
      let intVal = parseInt(value, 10)

      if (isNaN(intVal)) {
        intVal = 31
      } // >30 doesn't get parsed, force it here
      let sensitivity = this.currentTulpin.sensitivity
      let sensitiveLowerLimit = sensitivity[2]
      let maxSensitivityValue = 31 // The >30
      let sensitivitySpectrum = maxSensitivityValue - sensitiveLowerLimit // Get how many steps form the percentages
      let adjustedSensitivity = intVal - sensitiveLowerLimit // Adjust the val to the spectrum
      let percent = adjustedSensitivity * 100 / sensitivitySpectrum

      return percentages.find(array => array[0] >= percent)[1]
    },

    updateSuffix: function () {
      if (this.suffixOptions) {
        let processedSuffix = this.processSuffix(this.antibioticValue)
        if (processedSuffix) {
          this.resultSuffix = processedSuffix
        }
      }
    },

    getResultBase: function (composedResult) {
      return composedResult ? composedResult.replace(/!/g, "").replace(/\+/g, "") : "";
    },

    getResultPrefix: function (composedResult) {
      return composedResult ? composedResult.split(this.getResultBase(composedResult))[0] : "";
    },

    getResultSuffix: function (composedResult) {
      if (composedResult) {
        let resultBase = this.getResultBase(composedResult)
        if (resultBase === 'S') {
          let split = composedResult.split('S')
          return split[split.length - 1]
        }
      }
      return ""
    },

    defaultRadioOptions: function (ary) {
      return ary.map(t => ({text: t, value: t}))
    },

    fieldName: function (param = this.dataObject.name) {
      let paramName = this.dataObject.name
      if (param && param !== paramName) {
        paramName = `${paramName}_${param}`
      }
      return `bulletin_document[results][${paramName}]`
    },

    originalValue: function (param = this.dataObject.name) {
      let paramName = this.dataObject.name
      if (param && param !== paramName) {
        paramName = `${paramName}_${param}`
      }
      return this.valObject[paramName]
    },
  }
}
</script>

