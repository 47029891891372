<template>
    <b-form-input ref="datepicker" :name="name" v-model="date"/>
</template>

<script>
  import $ from 'jquery'
  import DateRangePickerLocale from './DateRangePickerLocale.js'
  import BFormInput from "../forms/BFormInput.vue";
  import dayjs from "dayjs";

  export default {
    components: {BFormInput},
    // Available pickerTypes 'daterangepicker', 'datepicker', 'datetimepicker'
    props: ['originalDate', 'name', 'pickerType'],

    data: function () {
      return {
        date: this.originalDate
      }
    },

    watch: {
      originalDate: function (changed) {
        this.date = changed
        let picker = $(this.$refs.datepicker.$el).data('daterangepicker')

        switch(this.pickerType) {
          case "daterangepicker": {
            if (picker) {
              let pickerStartDate = picker.startDate.format(picker.locale.format)
              let pickerEndDate = picker.endDate.format(picker.locale.format)

              if (this.date !== `${pickerStartDate} - ${pickerEndDate}`) {
                // this.date = "12-03-2017 - 13-03-2017"
                let dateSplit = this.date.split(' ')
                let startDateString = dateSplit[0]
                let endDateString = dateSplit[dateSplit.length - 1]
                picker.setStartDate(dayjs(startDateString, picker.locale.format))
                picker.setEndDate(dayjs(endDateString, picker.locale.format))
              }
            }
            break
          }
          case "datepicker": {
            if (picker && picker.startDate.format(picker.locale.format) !== this.date) {
              picker.setStartDate(dayjs(this.date, picker.locale.format))
              picker.setEndDate(dayjs(this.date, picker.locale.format))
            }
            break
          }
        }
      }
    },

    mounted: function () {
      let $el = $(this.$refs.datepicker.$el)

      switch(this.pickerType) {
        case "daterangepicker": {
          $el.daterangepicker({
            showDropdowns: true,
            autoUpdateInput: false, // gets updated on watch prop
            autoApply: true,
            locale: DateRangePickerLocale.locale.ro
          });
          break;
        }
        case "datepicker": {
          $el.daterangepicker({
            singleDatePicker: true,
            showDropdowns: true,
            autoUpdateInput: false, // gets updated on watch prop
            timePicker: false,
            autoApply: true,
            locale: DateRangePickerLocale.locale.ro
          });
          break
        }
      }

      $el.on('apply.daterangepicker', (ev, picker) => {
        this.$emit('dateRangeChanged', picker)
      })
    }
  }
</script>
