import $ from 'jquery'

$(function() {
  let locale = {
    "format": "DD-MM-YYYY",
    "separator": " - ",
    "applyLabel": "Aplica",
    "cancelLabel": "Anuleaza",
    "fromLabel": "De la",
    "toLabel": "Pana La",
    "customRangeLabel": "Personalizat",
    "daysOfWeek": [
      "Du",
      "Lu",
      "Ma",
      "Mi",
      "Jo",
      "Vi",
      "Sa"
    ],
    "monthNames": [
      "Ianuarie",
      "Februarie",
      "Martie",
      "Aprilie",
      "Mai",
      "Iunie",
      "Iulie",
      "August",
      "Septembrie",
      "Octombrie",
      "Noiembrie",
      "Decembrie"
    ],
    "firstDay": 1
  }
  let $dateTimePickers = $('[data-toggle="datetimepicker"]')
  let $datePickers = $('[data-toggle="datepicker"]')
  let $dateRangePickers = $('[data-toggle="daterangepicker"]')

  $dateTimePickers.daterangepicker({
    singleDatePicker: true,
    showDropdowns: true,
    autoUpdateInput: false,
    timePicker: true,
    timePicker24Hour: true,
    autoApply: true,
    locale: locale
  })

  $dateTimePickers.on('apply.daterangepicker', (e, picker) => {
    picker.element.val(picker.startDate.format('DD-MM-YYYY H:mm:ss'))
    picker.element.trigger('change')
  })

  $datePickers.daterangepicker({
    singleDatePicker: true,
    showDropdowns: true,
    autoUpdateInput: false,
    autoApply: true,
    locale: locale
  })

  $datePickers.on('apply.daterangepicker', (e, picker) => {
    picker.element.val(picker.startDate.format(picker.locale.format))
    picker.element.trigger('change')
    picker.element[0].dispatchEvent(new CustomEvent('input'))
  })

  $dateRangePickers.on('apply.daterangepicker', (e, picker) => {
    picker.element.val(picker.startDate.format(picker.locale.format) + ' - ' + picker.endDate.format(picker.locale.format))
    picker.element.trigger('change')
  })

  $dateRangePickers.daterangepicker({
    showDropdowns: true,
    autoUpdateInput: false,
    autoApply: true,
    locale: locale
  })
})
