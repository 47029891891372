<template>
  <div class="card">
    <div class="card-header">
      <b-form-checkbox
          :name="fieldName(agar.name)"
          v-model="checked"
          :checked-value="'1'"
          :unchecked-value="'0'">
        {{ agar.translated_name }}
      </b-form-checkbox>
    </div>

    <div class="card-body" v-show="checked === '1'">
      <div class="row">
        <div class="col-md-2">

          <date-range-picker
              :originalDate="date"
              :name="fieldName('date')"
              pickerType="datepicker"
              @dateRangeChanged="onAgarDateChanged"
              class="mb-2"/>

          <b-button variant="primary" class="mr-1" size="sm" @click="decreaseAgarDate">-1</b-button>
          <b-button variant="primary" class="mr-1" size="sm" @click="increaseAgarDate">+1</b-button>
          <b-button variant="primary" class="mr-1" size="sm" @click="todayAgarDate">Azi</b-button>
        </div>

        <div class="col-md-3">
          <b-form-input :name="fieldName('lot')" v-model="lot" class="mb-2"/>
          <b-button v-if="availableLot" variant="primary"
                    size="sm" @click="lot=availableLot">
            {{ availableLot }}
          </b-button>
        </div>

        <div class="col-md-3">
          <date-range-picker
              :originalDate="timeRange"
              :name="fieldName('time_range')"
              pickerType="daterangepicker"
              @dateRangeChanged="onAgarTimeRangeChanged"
              class="mb-2"/>
        </div>

        <div class="col-md-4">
          <b-form-radio-group
              v-model="agarTemp"
              :options="agarTempOptions"
              :name="fieldName('temp')"
              button-variant="light"
              class="mr-3 mb-xl-0 mb-sm-3"
          />

          <b-form-radio-group
              v-model="agarPeriod"
              :options="agarPeriodOptions"
              :name="fieldName('time')"
              button-variant="light"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import {ref, watch} from "vue";
import {BFormInput, BFormRadioGroup, BButton, BFormCheckbox} from "/vue/components/forms";
import DateRangePicker from '/vue/components/generic/DateRangePicker.vue'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat);

const props = defineProps({
  agar: Object
})

const dateFormat = "DD-MM-YYYY"
const timeValues = {
  '24h': 1,
  '48h-72h': 3,
  '5-6 zile': 6,
  '2-3 saptamani': 21,
  '7 zile': 7,
  '14 zile': 14
}

const originalValue = (param = props.agar.name) => {
  let paramName = props.agar.name
  if (param && param !== paramName) {
    paramName = `${paramName}_${param}`
  }
  return props.agar.object[paramName]
}

const availableLot = ref(props.agar.available_lot)
const checked = ref(originalValue(props.agar.name))
const date = ref(originalValue('date'))
const lot = ref(originalValue('lot'))
const timeRange = ref(originalValue('time_range'))
const agarPeriod = ref(originalValue('time'))
const agarTemp = ref(originalValue('temp'))

const agarPeriodOptions = props.agar.times.map(t => ({text: t, value: t}))
const agarTempOptions = props.agar.temps.map(t => ({text: t, value: t}))

watch(checked, (val) => {
  if (val === '1') {
    if (!date.value) date.value = dayjs().format(dateFormat);
    if (!lot.value) lot.value = availableLot.value;
    if (!agarPeriod.value) agarPeriod.value = props.agar.times[1];
    if (!agarTemp.value) agarTemp.value = props.agar.temps[1];
    updateTimeRange()
  } else {
    date.value = null;
    lot.value = null;
    agarPeriod.value = null;
    agarTemp.value = null;
  }
})

watch(date, (val) => {
  if (val) checked.value = '1';
  updateTimeRange()
})

watch(lot, function (val) {
  if (val) checked.value = '1'
})

watch(agarTemp, function (val) {
  if (val) checked.value = '1'
})

watch(agarPeriod, function (val) {
  if (val) checked.value = '1'
  updateTimeRange()
})

const fieldName = function (param = props.agar.name) {
  let paramName = props.agar.name
  if (param && param !== paramName) {
    paramName = `${paramName}_${param}`
  }
  return `bulletin_document[results][${paramName}]`
}

const onAgarDateChanged = function (picker) {
  date.value = picker.startDate.format(dateFormat);
}

const onAgarTimeRangeChanged = function (picker) {
  timeRange.value = picker.startDate.format(dateFormat) + ' - ' + picker.endDate.format(dateFormat)
}

const increaseAgarDate = function () {
  if (!date.value || date.value.length === 0) {
    todayAgarDate()
  }

  if (date.value.length > 0) {
    date.value = dayjs(date.value, dateFormat)
        .add(1, 'days')
        .format(dateFormat)
  }
}

const decreaseAgarDate = function () {
  if (!date.value || date.value.length === 0) {
    todayAgarDate()
  }

  if (date.value.length > 0) {
    date.value = dayjs(date.value, dateFormat)
        .subtract(1, 'days')
        .format(dateFormat)
  }
}

const todayAgarDate = function () {
  date.value = dayjs().format(dateFormat)
}

const updateTimeRange = function () {
  if (!date.value) return;
  let days = timeValues[agarPeriod.value];
  if (days > 0) {
    let endDate = dayjs(date.value, dateFormat).add(days, 'days').format(dateFormat);
    timeRange.value = `${date.value} - ${endDate}`
  }
}

</script>
