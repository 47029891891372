import $ from 'jquery'

$(function () {
    $('[data-text-completer]').on('click', function (e) {
        let $el = $(this);
        let $textarea = $('#' + $el.data('text-completer'));
        let textToAdd = $el.data('text-value');
        let method = $el.data('text-completer-method');
        if ($textarea.is('textarea') || $textarea.is('input[type=text]')) {
            if (method === 'replace') {
                $textarea.val(textToAdd);
                $textarea.focus();
                $textarea.prop('selectionEnd', textToAdd.length);
            } else if (method === 'add') {
                let caretPos = $textarea[0].selectionStart;
                if (caretPos === 0) {
                    caretPos = $textarea.val().length
                }
                let currentText = $textarea.val();
                let stringBeforeCursor = currentText.substring(0, caretPos) + textToAdd;
                $textarea.val(stringBeforeCursor + currentText.substring(caretPos))
                $textarea.focus();
                $textarea.prop('selectionEnd', stringBeforeCursor.length)
            }
        } else {
            let $trix = $('trix-editor[input=' + $el.data('text-completer') + ']')
            if ($trix[0]) {
                let $editor = $trix[0].editor;
                $editor.recordUndoEntry('Content updated');
                $editor.insertHTML(textToAdd + ' ');
            }
        }
    })
});