<template>
  <button @click.prevent class="btn" :class="buttonClasses">
    <slot/>
  </button>
</template>

<script setup lang="ts">
import {computed, defineProps} from "vue";
const props = defineProps({
  variant: String,
  size: String
})

const buttonClasses = computed(() => {
  return [variantName.value, sizeName.value].filter(n => n).join(" ")
})

const variantName = computed(() => {
  return props.variant ? `btn-${props.variant}` : null
})

const sizeName = computed(() => {
  return props.size ? `btn-${props.size}` : null
})

</script>