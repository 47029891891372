<template>
  <div class="card mb-3" v-if="editing">
    <div class="card-header">
      <div class="row">
        <div class="col-md-6"><h5>{{ agar.translated_name }}</h5></div>
        <div class="col-md-6 text-right">
          <b-button size="sm" variant="primary" @click="commitData">
            Adauga la Examen Aspect Cultural
          </b-button>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-md-3 btn-small-padding">
          <agar-step
              v-model="cultureSelection"
              step="culture_selection"
              :step-data="getStepData('culture_selection')"
          />
          <agar-step
              v-model="cultureOptions[stepName]"
              v-for="stepName in getSteps('culture_options')"
              :step-data="getStepData(stepName)"
              :step="stepName"
              v-if="cultureSelection === 'Cultura'"
          />
        </div>

        <div class="col-md-9" v-if="cultureSelection === 'Cultura'">
          <div class="mb-3 tw-flex tw-gap-3">
            <b-button @click="selectedColonies" variant="primary" size="sm">+ Colonii</b-button>
            <b-button @click="selectedGazon" variant="primary" size="sm">+ Gazon</b-button>
          </div>

          <table class="table table-bordered mb-3 table-sm">
            <tbody>
              <tr v-for="(colonySelection, colonySelectionIndex) in colonies">
                <td>
                  <div class="mb-3">
                    <div v-if="colonySelection.editing === true">
                      <b-button size="sm"
                                variant="success"
                                @click="colonySelection.editing = false">
                        Salveaza
                      </b-button>
                      <b-button size="sm"
                                variant="danger"
                                @click="removeColonySelectionAtIndex(colonySelectionIndex)">
                        Sterge
                      </b-button>
                    </div>
                    <div v-else>
                      <b-button size="sm"
                                variant="primary"
                                @click="colonySelection.editing = true">
                        Editeaza
                      </b-button>
                      <b-button size="sm"
                                variant="danger"
                                @click="removeColonySelectionAtIndex(colonySelectionIndex)">
                        Sterge
                      </b-button>
                    </div>
                  </div>

                  <div v-if="colonySelection.editing === true" class="btn-small-padding">
                    <agar-step
                        v-for="(stepName, index) in getSteps(colonySelection.name)"
                        v-model="colonySelection.options[stepName]"
                        :step-data="getStepData(stepName)"
                        :step="stepName"
                    ></agar-step>
                  </div>
                  <div v-else>
                    {{ getColonyText(colonySelection) }}
                  </div>
                </td>

              </tr>
            </tbody>
          </table>

          <div v-for="stepName in culturalAspectExam.other">
            <agar-step
                v-model="other[stepName]"
                :step-data="getStepData(stepName)"
                :step="stepName"
                :title="stepName"
                :stacked="false"
            ></agar-step>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import AgarStep from './AgarStep.vue'
import {BButton} from "/vue/components/forms";

export default {
  props: ['agar', 'culturalAspectExam'],
  data: function () {
    return {
      editing: true,
      cultureSelection: null,
      cultureOptions: {},
      colonies: [],
      other: {}
    }
  },

  methods: {
    commitData: function () {
      this.$emit('commitText', this.getFullText())
      this.editing = false
    },

    // stringTemplate = "param | param2 | param3", options = { param: 1, param2: 2, param3: 3 }
    buildText: function (stringTemplate, userChosenOptions) {
      if (Object.keys(userChosenOptions).length === 0) return '';

      let optionsArray = stringTemplate.split('|').map(el => {
        let filledOptionKey = Object.keys(userChosenOptions).find(key => el.includes(key))
        if (filledOptionKey) {
          return el.replace(filledOptionKey, userChosenOptions[filledOptionKey])
        }
        return null
      })
      // Remove empty elements
      optionsArray = optionsArray.filter(n => n)
      // Remove commas from the last element if any
      let lastOption = optionsArray[optionsArray.length - 1]
      if (lastOption.includes(':')) {
        // is bacteria
        let option = optionsArray[optionsArray.length - 2]
        if (option) {
          optionsArray[optionsArray.length - 2] = option.replace(',', '')
        }
      } else {
        optionsArray[optionsArray.length - 1] = optionsArray[optionsArray.length - 1].replace(',', '')
      }

      return optionsArray.join('')
    },

    getColonyText: function (colonySelection) {
      return this.buildText(colonySelection.stringTemplate, colonySelection.options)
    },

    getFullText: function () {
      if (this.cultureSelection === 'Steril') {
        return `<div><b>${this.agar.translated_name}</b>: ${this.cultureSelection}</div>`
      } else if (this.cultureSelection === 'Cultura') {
        let cultureStringTemplate = this.culturalAspectExam.culture_string
        let cultureString = this.buildText(cultureStringTemplate, this.cultureOptions)
        let str = `<div>
                        <b>${this.agar.translated_name}</b>: ${this.cultureSelection} ${cultureString}
                        <ul>`
        str += this.colonies.map(colony => {
          return `<li>${this.getColonyText(colony)}</li>`
        }).join('')
        str += '</ul>'

        if (Object.keys(this.other).length > 0) {
          str += `<ul>`
          str += Object.keys(this.other).map(key => `<li>${key}: ${this.other[key]}</li>`).join('')
          str += '</ul>'
        }

        return str + '</div>'
      }
      return ''
    },

    removeColonySelectionAtIndex: function (index) {
      this.colonies.splice(index, 1)
    },
    selectedColonies: function () {
      this.colonies.push({
        name: 'colony_options',
        stringTemplate: this.culturalAspectExam.colony_string,
        editing: true,
        options: {
          colony_selection: 'colonii'
        }
      })
    },

    selectedGazon: function () {
      this.colonies.push({
        name: 'gazon_options',
        stringTemplate: this.culturalAspectExam.gazon_string,
        editing: true,
        options: {
          colony_selection: 'gazon'
        }
      })
    },

    getSteps: function (name) {
      return this.culturalAspectExam[name]
    },

    getStepData: function (stepName) {
      return this.culturalAspectExam[stepName] || this.culturalAspectExam.agar_data[stepName]
    }
  },

  components: {AgarStep, BButton}
}
</script>