import $ from 'jquery'

$(function () {
  const $companyInput = $('#company-search')
  $companyInput.on('input', () => {
    const val = $companyInput.val()

    $('[data-company]').each((company, c) => {
      const $company = $(c)
      const string = $company.data('company')
      if (string.toLowerCase().indexOf(val) >= 0) {
        $company.show()
      } else {
        $company.hide()
      }
    })
  })
})